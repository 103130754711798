import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { UserAuthority, UserAuthorityModule, VWUserAuthorityModule } from './user-authority.module';





@Injectable({
    providedIn: 'root'
})
export class UserAuthorityService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }


    getService(UserID: number): Observable<UserAuthorityModule[]> {
        return this.http.get<UserAuthorityModule[]>(environment.apiURL + 'Manage/UserAuthority/search/' + UserID);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Manage/UserAuthority/' + id).toPromise();
    }

    formData: UserAuthorityModule = new UserAuthorityModule();
    list: UserAuthorityModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Manage/UserAuthority/', this.formData);
    }

    postListService(userAuthoritList: UserAuthority[]) {
        return this.http.post(environment.apiURL + 'Manage/UserAuthority/PostList/', userAuthoritList, { responseType: 'text' });
    }
    getServiceDelete(UserID: number) {
        return this.http.get(environment.apiURL + 'Manage/UserAuthority/DeleteList/?mgUserID=' + UserID, { responseType: 'text' });
    }

    putService() {
        return this.http.put(environment.apiURL + 'Manage/UserAuthority/' + this.formData.authorityID, this.formData);
    }


    getServiceDashboard(UserID: number, lang: string) {
        return this.http.get(environment.apiURL + 'View/ViewMNRegistration/Dashboard/', { responseType: 'text' });
    }

    getServiceUserMenu(UserID: number, lang: string) {
        return this.http.get(environment.apiURL + 'Manage/VWUserAuthority/UserMenu/?mgUserID=' + UserID + '&Lang=' + lang, { responseType: 'text' });
    }

    getServiceUserID(UserID: number): Observable<VWUserAuthorityModule[]> {
        return this.http.get<VWUserAuthorityModule[]>(environment.apiURL + 'Manage/VWUserAuthority/search/' + UserID);
    }
}
