import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStoreService {
    private ss = window.sessionStorage;

  constructor() {}

  public setItem(key, value) {
    value = JSON.stringify(value);
      this.ss.setItem(key, value);
    return true;
  }

  public getItem(key) {
      const value = this.ss.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }


    public getItemAuth(key) {
        const value = this.ss.getItem(key);
        try {
            if (value == null) {
                return false;
            }
            else {
                return JSON.parse(value);
            }
        } catch (e) {
            return false;
        }
    }


    public removeItem(key) {
      
        try {
            this.ss.removeItem(key);
        } catch (e) {
            return ;
        }
    }

  public clear() {
      this.ss.clear();
  }
}
