<div class="sidebar-panel">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">

            <!-- App Logo -->
            <div class="branding">
                <img src="assets/images/egret.png" alt="" class="app-logo">
                <span class="app-logo-text">Pay.Hekaya</span>

                <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span>
                <div
                class="sidebar-compact-switch"
                [ngClass]="{active: layoutConf.sidebarCompactToggle}"
                (click)="toggleCollapse()"
                *ngIf="layoutConf.sidebarStyle !== 'compact'"><span></span></div>
            </div>
            <!-- Sidebar user -->
            <div class="app-user">
                <div class="app-user-photo">
                <img src="assets/images/face-7.jpg" class="mat-elevation-z1" alt="">
                </div>
                <span class="app-user-name mb-8">
                <mat-icon class="icon-xs text-muted">lock</mat-icon>
                {{jwtAuth?.user?.displayName}} - User
                </span>
                <!-- Small buttons -->
                <div class="app-user-controls">
             
                <button
                class="text-muted"
                mat-icon-button
                mat-xs-button
                matTooltip="Sign Out"
                routerLink="/sessions/signin">
                    <mat-icon>exit_to_app</mat-icon>
                </button>
              
                </div>
            </div>
            <!-- Navigation -->
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
        </div>
    </div>
</div>