export const config = {


apiUrl: 'https://pay.hekaya.co/api/',
    apiUrl2: 'https://pay.hekaya.co/',


    authRoles: {
        sa: ['SA'], // Only Super Admin has access
        admin: ['SA', 'Admin'], // Only SA & Admin has access
        editor: ['SA', 'Admin', 'Editor'], // Only SA & Admin & Editor has access
        user: ['SA', 'Admin', 'Editor', 'User'], // Only SA & Admin & Editor & User has access
        guest: ['SA', 'Admin', 'Editor', 'User', 'Guest'] // Everyone has access
    }
}



//apiUrl: 'https://demopay.hekaya.co/api/',
//    apiUrl2: 'https://demopay.hekaya.co/',

//apiUrl: 'https://pay.hekaya.co/api/',
//    apiUrl2: 'https://pay.hekaya.co/',


//    apiUrl: 'http://localhost:7271/api/',
//    apiUrl2: 'http://localhost:7271/',

