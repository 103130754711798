import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserAuthorityService } from '../../appshared/manage/user-authority/user-authority.service';
import { SessionStoreService } from './session-store.service';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;  // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {

    iconMenu: IMenuItem[] = [

        {
            name: 'Dashbourd',
            type: 'link',
            icon: 'dashboard',

            state: 'others/blank'
        }, {
            name: 'Master',
            type: 'dropDown',
            tooltip: 'Master',
            icon: 'filter_none',
            state: 'master',
            sub: [
                { name: 'Attachment_Type', state: 'attachment_type' },
                { name: 'Ordinary_Document', state: 'ordinary_document' },
                { name: 'Language', state: 'language' },
                { name: 'Religion', state: 'religion' },
                { name: 'Country', state: 'country' },
                { name: 'Nationality', state: 'nationality' },
                { name: 'Enquiry_Type', state: 'enquiry_type' },
                { name: 'Input_Types', state: 'input_types' },
                { name: 'Currency', state: 'currency' },
                { name: 'Promotion_Code', state: 'promotion_code' },
                { name: 'Insurance_Group', state: 'insurance_group' },
                { name: 'Job_Category', state: 'job_category' }
            ]
        },

        {
            name: 'Visa',
            type: 'link',
            icon: 'flight_takeoff',
            state: 'visa/visa-country'
        },
        {
            name: 'Order',
            type: 'link',
            icon: 'description',
            state: 'order/order'
        },
        {
            name: 'Agent',
            type: 'dropDown',
            tooltip: 'Agent',
            icon: 'person',
            state: 'agent',
            sub: [
                { name: 'Agent', state: 'agent' },
                { name: 'New_Agent_Request', state: 'new-agent-request' }
            ]
        },

        {
            name: 'Web_Tools',
            type: 'dropDown',
            tooltip: 'Web Tools',
            icon: 'library_books',
            state: 'web-tools',
            sub: [
                { name: 'Feedback', state: 'feedback' },
                { name: 'FAQ', state: 'FAQ' },
                { name: 'Multipage', state: 'multipage' },
                { name: 'Visitor_Report', state: 'visitor-report' }
            ]
        }
        ,

        {
            name: 'Management',
            type: 'dropDown',
            tooltip: 'Management',
            svgIcon: 'ulb_settings',
            state: 'manage',
            sub: [
                { name: 'Company_Info', state: 'company-info' },
                { name: 'Users', state: 'users' },
                { name: 'User_Monitor', state: 'user-monitor' },
                { name: 'SMS_Settings', state: 'sms-settings' },
                { name: 'Email_Settings', state: 'email-settings' }
            ]
        }

        //{
        //    name: 'Agent',
        //    type: 'dropDown',
        //    tooltip: 'Agent',
        //    icon: 'filter_none',
        //    state: 'agent-cp',
        //    sub: [
        //        { name: 'Step1', state: 'step1' }
        //    ]
        //}

    ];



  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();



    constructor(private servUser: UserAuthorityService,
        private sessionS: SessionStoreService) {
      
    }

        userID = 1;
    Lang = "En";

 

    public fillMenu() {

        this.iconMenu = null;

        var pageAu = this.sessionS.getItemAuth('mgUserID');

        this.servUser.getServiceUserMenu(+pageAu, "En")
            .subscribe(res => {
                var json = JSON.parse(res);
                this.iconMenu = json as IMenuItem[];
             
            })

       

        //this.http.get(environment.apiURL + 'Manage/VWUserAuthority/UserMenu/?mgUserID=1&Lang=En', { responseType: 'text' })
        //    .subscribe(
        //        res => {
        //            alert(res);
        //            try {
        //                var json = JSON.parse(res);

        //                this.iconMenu = json as IMenuItem[];
        //            } catch (e) {
        //                alert(e);
        //            }
        //        });
    }
  






  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    // switch (menuType) {
    //   case 'separator-menu':
        // this.menuItems.next(this.separatorMenu);
        // break;
    //   case 'icon-menu':
        this.menuItems.next(this.iconMenu);
        // break;
    //   default:
        // this.menuItems.next(this.plainMenu);
    // }
  }
}
