import { Injectable } from "@angular/core";
import { LocalStoreService } from "../local-store.service";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError, delay } from "rxjs/operators";
import { User } from "../../models/user.model";
import { of, BehaviorSubject, throwError } from "rxjs";
import { environment } from "environments/environment";
import { UserAuthorityService } from "../../../appshared/manage/user-authority/user-authority.service";
import { VWUserAuthorityModule } from "../../../appshared/manage/user-authority/user-authority.module";
import { MatTableDataSource } from "@angular/material/table";
import { SessionStoreService } from "../session-store.service";


// ================= you will get those data from server =======

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  token;
  isAuthenticated: Boolean;
  user: User = {};
  profile2: User = {};
  user$ = (new BehaviorSubject<User>(this.user));
  signingIn: Boolean;
  return: string;
  JWT_TOKEN = "JWT_TOKEN";
  APP_USER = "EGRET_USER";

  constructor(
    private ls: LocalStoreService,
      private sessionS: SessionStoreService,
    private http: HttpClient,
    private router: Router,
      private route: ActivatedRoute,
      private servUserAuthority: UserAuthorityService
  ) {
    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/');
  }
      
  public signin(username, password) {
      this.signingIn = true;
      return this.http.post(`${environment.apiURL}Manage/MGUser/Login`, { username, password })
          .pipe(
              map((res: any) => {
                  this.setUserAndToken(res.token, res.user, !!res);
                  this.signingIn = false;
                  return res;
              }),
              catchError((error) => {
                  return throwError(error);
              })
          );
  }

  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
  public checkTokenIsValid() {
      return this.http.get(`${environment.apiURL}Manage/UserProfile`, { headers: this.token })
          .pipe(
              map((profile: any) => {
                  this.setUserAndToken(this.getJwtToken(), profile.user, true);
                  return profile;
              }),
              catchError((error) => {
                  this.signout();
                  return of(error);
              })
          );
  }

  public signout() {
    this.setUserAndToken(null, null, false);
    this.router.navigateByUrl("sessions/signin");
  }

  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }
  getUser() {
    return this.ls.getItem(this.APP_USER);
  }

    setUserAndToken(token: String, user: User, isAuthenticated: Boolean) {
        this.isAuthenticated = isAuthenticated;
        this.token = token;
        this.user = user;
        this.user$.next(user);
        this.ls.setItem(this.JWT_TOKEN, token);
        this.ls.setItem(this.APP_USER, user);
        if (token != null) {
            this.setUserAuth(this.user.mgUserID);
        }
    }

    public dataSource = new MatTableDataSource<VWUserAuthorityModule>();

    setUserAuth(mgUserID: any) {

        this.sessionS.setItem('mgUserID', mgUserID);

        this.servUserAuthority.getServiceUserID(mgUserID)
            .subscribe(res => {
                this.dataSource.data = res as VWUserAuthorityModule[];

                for (var i = 0; i < this.dataSource.data.length; i++) {

                    this.sessionS.setItem(this.dataSource.data[i].code, this.dataSource.data[i].status);
                }
            })


    }
}
