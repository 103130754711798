export class UserAuthorityModule {
    userAuthorityID: number = 0;
    mgUserID: number = 0;
    status: boolean = true;
    authorityID: number = 0;
}

export interface UserAuthority {
    userAuthorityID: number;
    mgUserID: number;
    status: boolean;
    authorityID: number;
}

export class VWUserAuthorityModule {
    userAuthorityID: number = 0;
    mgUserID: number = 0;
    status: boolean = true;
    authorityID: number = 0;
    nameAr: string = '';
    nameEn: string = '';
    sourceName: string = '';
    pageLink: string = '';
    code: string = '';
    parentID?: number = 0;
    types: string = '';
    icon: string = '';
    state: string = '';

}