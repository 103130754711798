import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
        redirectTo: 'sessions/signin',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session'}
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
    
        {
            path: 'master',
            loadChildren: () => import('./appviews/Master/masters.module').then(m => m.MastersModule),
            data: { title: 'Masters', breadcrumb: 'Masters' }
        },
        {
            path: 'web-tools',
            loadChildren: () => import('./appviews/web-tools/web-tools.module').then(m => m.WebToolsModule),
            data: { title: 'Web_Tools', breadcrumb: 'Web_Tools' }
        },
        {
            path: 'manage',
            loadChildren: () => import('./appviews/manage/manage.module').then(m => m.ManageModule),
            data: { title: 'Management', breadcrumb: 'Management' }
        }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

